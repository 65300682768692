import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import home from './components/home';
import status from './components/status';

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/" exact component={home} />
        <Route path="/simple" exact component={status} />
      </Switch>
    </Router>
  );
}

export default App;
